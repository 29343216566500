html {
  scroll-behavior: smooth;
}

html,
body {
  scroll-behavior: smooth;
}

/* section {
  height: 650px;
} */

body {
  background-color: #e9e8e4;
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
}

body.sidebar-open {
  overflow: hidden;
}

#backgroundVideo {
  min-width: auto;
  object-fit: cover;
}

@font-face {
  font-family: "AgencyFB";
  src: url("./assets/fonts/AGENCYB.TTF") format("truetype");
  font-weight: normal;
  font-style: normal;
}

.intro.your-own-app-intro {
  bottom: 95px;
}

.playbtn {
  height: 40px;
}

@keyframes fadeOutIn {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.header {
  font-family: "AgencyFB", sans-serif;
  text-transform: uppercase;
}

.animate {
  display: block;
  height: 0;
  overflow: hidden;
  transition: height 0.4s cubic-bezier(0.4, 0, 0.2, 1),
    opacity 0.4s cubic-bezier(0.4, 0, 0.2, 1);
  opacity: 0;
}

.section-about .show-more .animate {
  height: 90px;
  opacity: 1;
}

.section-about .show-more .animate-penthouse {
  height: 310px;
}
.intro {
  width: 84%;
  vertical-align: initial;
  left: 50%;
  transform: translateX(-50%);
  position: absolute;
  bottom: 190px;
  opacity: 1;
  animation: fadeOutIn 4s ease-in-out 1 forwards;
}

.intro.hero {
  vertical-align: initial;
  left: 1.4%;
  top: 29%;
  transform: none;
  bottom: 0;
}

.intro .intro-title {
  width: 80%;
  font-size: 4.5rem;
  color: #fff;
  text-align: left;
  display: flex;
  flex-direction: column;
  gap: 25px;
}

.intro.show {
  opacity: 1;
}

.intro_title {
  font-size: 30px;
  text-transform: uppercase;
  color: #fff;
  line-height: 1.43;
  letter-spacing: 0.07em;
}

.shell {
  /* max-width: 1268px; */
  padding: 0 50px;
  margin: 0 auto;
}

.fade-in-section {
  opacity: 0;
  transform: translateY(100px);
  transition: opacity 1s ease-out, transform 1s ease-out;
}

.fade-in-section.show {
  opacity: 1;
  transform: translateY(0);
}

#banner {
  padding-top: 0px;
}

em {
  display: inline-block;
  margin-left: -2px;
  text-transform: none;
  letter-spacing: 0;
  font-weight: 400;
  font-style: italic;
}

#hero video {
  /* height: 56.25vw; */
  min-height: 100vh;
  object-fit: cover;
}

#hero .thumb {
  /* height: 56.25vw; */
  min-height: 100vh;
  min-width: 177.77vh;
}

#hero .thumb img {
  width: 100%;
  height: 100%;
  object-fit: "cover";
}

.banner_slide {
  background-color: rgb(64, 78, 63);
  color: white;
  text-transform: uppercase;
  height: 58px;
}

.sidebar {
  padding: 45px 45px;
}

nav a {
  display: flex;
  text-transform: uppercase;
  font-weight: 500;
  font-style: normal;
  line-height: 34px;
  letter-spacing: 0.09em;
  text-decoration: none;
  font-size: 25px;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
}

.section-entry {
  max-width: 585px;
  margin: 0 auto;
  text-align: center;
}

.text-p {
  font-size: 20px;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: 1.2px;
  text-align: center;
}

.text-p-s {
  font-size: 20px;
  letter-spacing: 0.02em;
  line-height: 1.3;
  color: #000;
  font-weight: 300;
  text-align: center;
}

.text-p-m {
  font-size: 16px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 1px;
  text-align: center;
}

.text-p-xs {
  font-size: 15px;
  font-weight: 300;
  line-height: 21px;
  text-align: center;
  text-underline-offset: 4px;
}

.text-p-xxxs {
  font-weight: 300;
  font-size: 16px;
  line-height: 1.3;
  color: #000;
  text-align: center;
}

.text-p-xxs {
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
  text-align: center;
}

.title {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 1.1px;
  text-align: center;
  width: 100%;
}

footer h2 {
  font-size: 22px;
  font-weight: 500;
  line-height: 1.27;
  letter-spacing: 1.76px;
  color: #e9e8e4;
  text-transform: uppercase;
  text-align: center;
}

.terms {
  font-size: 10.5px;
  font-style: italic;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 1px;
  text-align: center;
}

.artists-text {
  font-size: 12px;
  font-style: italic;
  font-weight: 400;
  line-height: 18px;
  text-align: center;
}

.btn-typography {
  appearance: none;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 1.28px;
}

.section-about h4 {
  margin-bottom: 13px;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 0.09em;
  font-size: 20px;
}

.section-about p {
  max-width: 538px;
  padding-right: 50px;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.02em;
}

@keyframes slideInLeft {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }

  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.animate-slide-in-left {
  opacity: 0;
}

.animate-slide-in-left.show {
  animation: slideInLeft 1s ease-out forwards;
}

.btn-label {
  padding: 13px 0 0;
  margin: 0;
  font-size: 14px;
  font-weight: 400;
  font-style: italic;
  color: #000;
  letter-spacing: 0;
}

.btn-color-scheme {
  min-width: 137px;
  border: 0;
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  padding: 9px 14px 7px;
  background: #3c3d3f;
  line-height: 1.1;
  font-size: 18px;
  font-weight: 400;
  color: #969696;
  text-align: center;
  cursor: pointer;
  text-transform: uppercase;
  text-decoration: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  letter-spacing: 0.09em;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.btn-color-scheme:hover {
  background-color: #6f7070;
}

.btn-color-scheme-white {
  background: #f9f8f6;
}

.btn {
  @apply text-p-xs bg-black text-white border py-2 shadow-md transition-colors duration-300;
}

.btn:hover {
  @apply bg-gray-100 text-black border-black;
}

.btn-gray {
  @apply text-p-xs bg-cream border py-2 px-6 shadow-md text-black btn-typography;
}

.btn-gray:hover {
  @apply bg-black text-white border-white;
}

.btn-jamie {
  @apply px-0 uppercase;
}

.flex-row-center {
  @apply flex justify-center items-center flex-row;
}

.flex-center {
  @apply flex justify-center items-center flex-col md:flex-row;
}

.flex-around {
  @apply flex justify-around items-center flex-col md:flex-row;
}

.flex-row-between {
  @apply flex justify-between items-center flex-row;
}

.flex-between {
  @apply flex justify-between items-center flex-col md:flex-row;
}

.border-top {
  @apply border-t border-gray-1 text-center;
}

.nav-list {
  @apply transition-transform duration-500 ease-in-out transform hover:translate-x-5;
}

.m-img-fixed {
  margin-top: 80vh;
}

.info {
  height: 640px;
}

.watch-video {
  object-fit: cover;
  height: 100%;
}

.virtual-tour {
  height: 80vh;
}

.tick {
  height: 28px;
}

.thumbnail {
  position: absolute;
  top: 35%;
  left: 50%;
  cursor: pointer;
  transform: translate(-50%, -50%);
}

.thumbnail-label {
  position: absolute;
  top: 42%;
  left: 50%;
  transform: translate(-50%, -44%);

  padding: 2px 8px 2px 8px;
  color: #fff;
  font-size: 1rem;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  text-transform: uppercase;
  cursor: pointer;
}

@keyframes fadeIn {
  to {
    opacity: 1;
  }
}

.scroll {
  position: absolute;
  bottom: 60px;
  text-align: center;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  cursor: pointer;
}

.scroll p {
  letter-spacing: 1.5px;
  font-size: 14px;
}

.arrow {
  height: 24px;
  margin: auto;
  animation: moveUpDown 2s ease-in-out infinite;
}

.arrow-down {
  height: 32px;
  margin: auto;
  transform-origin: center bottom;
  animation: arrowDown 1.8s infinite;
}

@keyframes arrowDown {
  from,
  20%,
  30%,
  40%,
  50%,
  60%,
  to {
    transform: translate3d(0, 0, 0);
  }

  15% {
    animation-timing-function: ease-out;
    transform: translate3d(0, -20px, 0);
  }

  25% {
    animation-timing-function: ease-out;
    transform: translate3d(0, 6px, 0);
  }

  35% {
    animation-timing-function: ease-out;
    transform: translate3d(0, -3px, 0);
  }

  45% {
    animation-timing-function: ease-in;
    transform: translate3d(0, 2px, 0);
  }
}

@keyframes moveUpDown {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(10px);
  }

  100% {
    transform: translateY(0);
  }
}

.fixed-image {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  /* height: 100vh; */
  /* background-image: url('./images/101.jpeg'); */
  background-size: cover;
  background-position: center;
  z-index: -1;
}

.slick-prev:before,
.slick-next:before {
  display: none;
}

#renderModal .slick-prev,
#renderModal .slick-next,
section .slick-prev,
section .slick-next {
  width: 60px;
  height: 60px;
  z-index: 999;
  top: 45%;
}

section .swap-slick-prev,
section .swap-slick-next {
  top: 90%;
}

#renderModal .slick-next {
  right: -65px;
}

#renderModal .slick-prev {
  left: -65px;
}

section .slick-next {
  right: 25px;
}

section .swap-slick-next {
  right: -180%;
}

section .slick-prev {
  left: 25px;
}

.swap-slick-prev {
  left: calc(100% + 735px) !important;
}

.slick-dots {
  bottom: -40px !important;
  z-index: 99999;
}

.slick-dots li {
  width: 10px !important;
  height: 10px !important;
  background: #e9e8e4;
  border: 1px solid #a5a5a5;
  margin: 0 5px;
  transition: background-color 0.3s ease;
}

.slick-dots li.slick-active {
  background: black;
}

.slick-dots li button:before {
  top: -5px !important;
  left: -5px !important;
  color: transparent !important;
}

.slick-slide {
  padding: 0 10px;
}

@keyframes sliderInLeft {
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(0);
  }
}

/* Apply the animation to the .slidebar-box-h1 class */
.slidebar-box-animate,
.slidebar-box-animate-media {
  animation: sliderInLeft 1s ease-out forwards;
}

.slidebar-box-animate-info {
  animation: sliderInLeft 1.2s ease-out forwards;
}

.slidebar-box-animate-header {
  animation: sliderInLeft 1.4s ease-out forwards;
}

.slick-list {
  margin: 0 -10px;
}

#hero .slick-slide {
  padding: 0;
}

.carousel-full-screen .slick-slide img {
  display: block;
  height: 90vh;
  object-fit: cover;
}

#custom-apps .slick-slide .cover-image {
  height: 48vh;
  object-fit: contain;
}

.slide img {
  width: 100%;
  display: block;
  border-radius: 10px;
}

.image-slide {
  position: relative;
  height: 674px;
  width: 100%;
  overflow: hidden;
}

.image-container {
  /* display: flex;
  width: 100%;
  height: 100%;
  position: relative; */

  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  overflow: hidden;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  pointer-events: none;
}

.half-image {
  width: 100%;
  /* Each half occupies 50% of the width */
  height: 100%;
  overflow: hidden;
  /* Hide the overflow */
  position: absolute;
  /* Use absolute positioning */
}

.bg-half {
  left: 0;
  /* Positioning the bgImage on the left */
}

.overlay-half {
  right: 0;
  /* Positioning the overlayImage on the right */
}

.bg-half img,
.overlay-half img {
  height: 100%;
  width: 200%;
  /* Each image is twice the width of its container */
  position: relative;
  top: 0;
}

.bg-half img {
  left: -50%;
  /* Shift the bgImage left to show its right half */
}

.overlay-half img {
  left: 0;
  /* Show the left half of the overlayImage */
}

.resize {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 50%;
  overflow: hidden;
  -webkit-transition: width 0.6s;
  -o-transition: width 0.6s;
  transition: width 0.6s;
}

.image-container img {
  /* width: 100%; */
  display: block;
  max-width: none;
}

.image-items {
  position: relative;
  width: 100%;
  height: auto;
  padding-top: 132%;
  -webkit-background-size: cover;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

/* checkbox */
input[type="checkbox"] {
  visibility: hidden;
}

input[type="checkbox"] + label {
  position: relative;
  padding-left: 30px;
  cursor: pointer;
  font-size: 16px;
}

input[type="checkbox"] + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 2px;
  width: 20px;
  height: 20px;
  background-color: transparent;
  border: 2px solid black;
  display: inline-block;
  box-sizing: border-box;
}

input[type="checkbox"]:checked + label:before {
  background-color: transparent;
  padding: 4px;
}

input[type="checkbox"]:checked + label:after {
  content: "";
  position: absolute;
  left: 4px;
  top: 6px;
  width: 12px;
  height: 12px;
  background-color: #333;
}

.copyright {
  position: absolute;
  right: 10px;
  bottom: 15px;
}

/* checkbox */
.map {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: inherit;
}

.slidebar-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 485px;
  background-color: transparent;
  height: 58.55vw;
  z-index: 99;
}

.slidebar {
  width: 0;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.slidebar-wrapper {
  background: linear-gradient(135deg, #97897b, #b2a497);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
  height: 55.7vw;
  min-height: 100vh;
  padding-top: 10px;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
}

.slidebar-box-h1 {
  font-family: "Antonio", sans-serif;
  font-optical-sizing: auto;
  font-size: 31px;
  font-weight: 500;
  letter-spacing: 1.1px;
  line-height: 40px;
  width: auto;
  height: auto;
  display: block;
  color: #000;
}

.slidebar-box-h2 {
  font-size: 26px;
  font-weight: 400;
}

.slidebar-box-text {
  font-size: 20px;
  font-weight: 400;
  text-transform: none;
  text-decoration: none;
  letter-spacing: 0px;
  line-height: 26px;
  text-align: left;
  white-space: normal;
  padding: 0px;
  color: #000;
}

@media (max-width: 768px) {
  #renderModal .slick-prev,
  #renderModal .slick-next,
  section .slick-prev,
  section .slick-next {
    width: 30px;
    height: 30px;
  }

  #renderModal .slick-next {
    right: -18px;
  }

  #renderModal .slick-prev {
    left: -18px;
  }

  .scroll {
    display: none;
  }

  .slidebar-box-text {
    font-size: 18px;
  }

  .slidebar-overlay {
    width: 100%;
    top: -35px;
    z-index: 0;
  }

  #custom-apps .slick-slide .cover-image {
    height: 100%;
  }

  .carousel-full-screen .slick-slide img {
    height: 100%;
  }

  .intro .intro-title {
    width: 100%;
  }
}

@media only screen and (max-width: 600px) {
  .shell {
    padding: 0 25px;
  }

  .slidebar-box-animate {
    animation: none;
    transition: none;
  }

  header .text-p-m {
    font-size: 10px;
    font-weight: 500;
  }

  .slidebar-box-h1 {
    font-size: 24px;
  }

  .slidebar-box-h2 {
    font-size: 20px;
  }

  .slidebar-enquire-btn {
    position: absolute;
    z-index: 999;
    bottom: 40px;
    width: 100%;
  }

  .slidebar-wrapper {
    min-height: 105vh;
    border-radius: 0;
  }

  #custom-apps .slick-slide .cover-image {
    height: 100%;
  }

  .intro.your-own-app-intro {
    bottom: 40px;
  }

  .vision-to-life-intro {
    top: 50%;
  }

  .cinematic-intro {
    top: 46%;
  }

  .virtual-tour-intro {
    top: 5%;
  }

  .intro {
    width: 100%;
  }

  .intro .intro-title {
    width: 100%;
    font-size: 22px;
  }

  .playbtn {
    height: 25px;
  }

  .intro_title {
    font-size: 14px;
  }

  .thumbnail-label {
    top: 28%;
  }

  .carousel-full-screen .slick-slide img {
    height: 25vh;
  }

  section .slick-prev,
  section .slick-next {
    width: 35px;
    height: 35px;
    top: 45%;
  }

  .virtual-tour {
    height: auto;
  }

  section {
    height: auto;
  }

  #banner {
    padding-top: 0;
  }

  .sidebar {
    width: 100%;
  }

  .m-img-fixed {
    margin-top: 40vh;
  }

  #hero {
    min-width: auto;
    object-fit: cover;
    height: 100%;
    min-height: 95vh;
  }

  .info {
    height: 440px;
  }
}

@media only screen and (max-width: 350px) {
  .slidebar-box-h1 {
    font-size: 20px;
    letter-spacing: 0.5px;
  }

  .slidebar-box-h2 {
    font-size: 18px;
  }

  .slidebar-box-text {
    font-size: 18px;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
}

.carousel-image-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
}

.carousel-image {
  width: 100%;
  height: 100%;
}

@media (orientation: landscape) {
  #backgrounVideo {
    display: none;
  }
}

@media only screen and (max-width: 950px) and (max-height: 450px) {
  .slidebar-overlay {
    top: 0;
  }

  .slidebar-overlay img {
    display: none;
  }

  .slidebar-wrapper {
    height: 78vw;
  }

  #renderModal .slick-slide img {
    object-fit: contain;
  }
}

#renderModal div {
  height: 100%;
}

#renderModal .slick-slide img {
  display: block;
  height: 100%;
  object-fit: cover;
}

#renderModal .slick-arrow img {
  height: 100%;
  margin: auto;
}
