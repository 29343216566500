/* ./src/index.css */
@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";


@keyframes imageSwap {

  0%,
  50% {
    opacity: 1;
  }

  /* First image visible first half */
  50.01%,
  100% {
    opacity: 0;
  }

  /* Then fade out for the second half */
}

@keyframes imageSwapReverse {

  0%,
  50% {
    opacity: 0;
  }

  /* Second image hidden first half */
  50.01%,
  100% {
    opacity: 1;
  }

  /* Then fade in for the second half */
}
